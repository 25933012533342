import React, { useState, useEffect } from 'react';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import { fetchUser } from '../redux/userSlice';
import { useDispatch } from 'react-redux';
import {LoaderComp} from '../UI/Loader'
const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const loadUser = async () => {
      setLoading(true);
      dispatch(fetchUser());
      setLoading(false);
    };

    loadUser();
  }, [dispatch]);


  useEffect(() => {
    setSidebarOpen(false);
  }, [children]);


  if(loading)
  {
    return <LoaderComp/>
  }

  return (
      <div className="flex h-full">
        {sidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-40 z-[999] md:hidden"
            onClick={() => setSidebarOpen(false)}
          />
        )}
        <div className={`bg-[#141414] fixed md:relative top-0 left-0 right-0 min-h-full px-5 text-center text-white z-[99999] transition-transform duration-300 transform ${sidebarOpen ? 'translate-y-0' : '-translate-y-full md:translate-y-0'}`}>
          <Sidebar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
        </div>
        <div className="flex flex-col flex-1">
          <Navbar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
          <main className="flex-1 bg-black text-white">
            {children}
          </main>
        </div>
      </div>
    
  );
};

export default Layout;

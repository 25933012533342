import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { FaTimes } from 'react-icons/fa';

const BeatForm = ({ beatType, onClose }) => {
    return (
        <div className="bg-[#141414] min-h-full p-6 rounded-lg">
            <div className="flex items-center justify-center gap-3">
                <h1 className='text-white font-bold flex text-center'>Add New Beat</h1>
                <FaTimes onClick={onClose} className='cursor-pointer relative left-3 lg:left-24 flex justify-end text-end text-white' />
            </div>
            <h2 className="text-base text-white py-3 text-center font-bold">{`${beatType} Beat Form`}</h2>
            <form className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>Beat Title</label>
                    <input type="text" placeholder="Enter beat title" className="p-2 border rounded-md" />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>Artist Name</label>
                    <input type="text" placeholder="Enter artist name " className="p-2 border rounded-md" />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>Category</label>
                    <select
                        defaultValue=""
                        className="p-2 border rounded-md"
                    >
                        <option value="" disabled>
                            Select Category
                        </option>
                        <option value="Electronic">Electronic</option>
                        <option value="Hip Hop">Hip Hop</option>
                        <option value="Rock">Rock</option>
                        <option value="Jazz">Jazz</option>
                    </select>
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>BPM</label>
                    <input type="text" placeholder="Enter BPM" className="p-2 border rounded-md" />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>File Link</label>
                    <input type="text" placeholder="Enter file link" className="p-2 border rounded-md" />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>Release Date</label>
                    <input type="date" placeholder="Enter Date" className="p-2 border rounded-md" />
                </div>
                <div className="flex flex-col gap-1">
                    <label htmlFor="" className='text-[#FFFFFF]'>Tags</label>
                    <input type="text" placeholder="Enter tags" className="p-2 border rounded-md" />
                </div>
                {beatType === 'Exclusive' && (
                    <div className="flex flex-col gap-1">
                        <label htmlFor="" className='text-[#FFFFFF]'>Price</label>
                        <input type="text" placeholder="Enter price" className="p-2 border rounded-md" />
                    </div>
                )}
                <div className="py-2">
                    <button
                        type="submit"
                        className="bg-red-500 w-full text-white py-2 px-4 rounded-md hover:bg-red-600"
                    >
                        Submit
                    </button>
                </div>
            </form>
        </div>
    );
};

const BeatTypeModal = ({ isOpen, onClose }) => {
    const [selectedBeatType, setSelectedBeatType] = useState(null);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="absolute inset-0 flex items-center 2xl:top-0 top-[24rem] md:top-[14rem]  justify-center z-50">
            <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm"></div>
            <div className="relative shadow-lg w-full px-2 max-w-sm z-10">
                {selectedBeatType ? (
                    <BeatForm beatType={selectedBeatType} onClose={onClose} />
                ) : (
                    <div className="bg-white p-4 rounded-lg">
                        <div className="py-4 flex justify-between items-center">
                            <h2 className="text-xl font-bold">Select Beat Type</h2>
                            <FaTimes onClick={onClose} className='cursor-pointer'/>
                        </div>
                        <div className="flex flex-col gap-4">
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                                onClick={() => setSelectedBeatType('Exclusive')}
                            >
                                Exclusive Beat
                            </button>
                            <button
                                className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                                onClick={() => setSelectedBeatType('Non-Exclusive')}
                            >
                                Non-Exclusive Beat
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>,
        document.body
    );
};

export default BeatTypeModal;

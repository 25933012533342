import React from 'react'
import UserLayout from '../../layout/UserLayout'
import edit from '../../assets/edit.svg'
import trash from '../../assets/trash.svg'
import Section from '../../components/Section'

const UserManagement = () => {

    const users = [
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
        { name: 'David Gray', email: 'david.gray@example.com', status: 'Free', date: '10-06-2024' },
    ];

    return (
        <>
            <UserLayout>
                <Section>
                    <div className="md:px-14 px-5 min-h-screen">
                        <h1 className="text-2xl font-bold text-white py-8">User Management</h1>
                        <h2 className="text-xl font-semibold text-[#9F9F9F] mb-2">Users</h2>
                        <div className="overflow-x-auto">
                            <table className="w-full text-white rounded-lg">
                                <thead>
                                    <tr className="text-left bg-[#141414] border-b border-[#9F9F9F]">
                                        <th className="p-3">Name</th>
                                        <th className="p-3">Email</th>
                                        <th className="p-3">Status</th>
                                        <th className="p-3">Registration date</th>
                                        <th className="p-3">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="space-y-2">
                                    {users.map((user, index) => (
                                        <React.Fragment key={index}>
                                            <tr className="bg-[#141414] text-[#9F9F9F] rounded-lg">
                                                <td className="p-4">{user.name}</td>
                                                <td className="p-4">{user.email}</td>
                                                <td className="p-4">{user.status}</td>
                                                <td className="p-4">{user.date}</td>
                                                <td className="p-4 flex gap-2">
                                                    <button>
                                                        <img src={edit} alt='' />
                                                    </button>
                                                    <button>
                                                        <img src={trash} alt='' />
                                                    </button>
                                                    <button className="px-3 py-1 bg-red-500 text-white rounded-lg hover:bg-red-600">
                                                        Promote
                                                    </button>
                                                </td>
                                            </tr>
                                            {index < users.length - 1 && <tr className="h-4 rounded-lg bg-black"></tr>}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Section>
            </UserLayout>
        </>
    )
}

export default UserManagement;

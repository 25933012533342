
import { useEffect } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";


export default function PaymentSuccess() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); 
    }, 5000); 

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex justify-center items-center min-h-[75vh] bg-right bg-no-repeat bg-contain">
      <div className="flex flex-col justify-center items-center text-center">
      
        <IoIosCheckmarkCircle color="green"  size={100}/>
        {/* Message Content */}
        <h1 className="text-2xl font-bold mt-4">Payment Successful</h1>
        <p className="text-lg text-gray-600 mt-2">Creating your dashboard experience...</p>
      </div>
    </div>
  );
}

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backend_url } from '../libs/data';

export const fetchUser = createAsyncThunk('users/fetchUsers', async () => {
      const email = localStorage.getItem("email");
    if(email)
    {
        const response = await axios.post(`${backend_url}/api/getuser`,{email});
        return response.data.user;
    }
   
});

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {}, 
        loading: false,
        error: null,
    },
    reducers: {
        clearUser: (state) => {
            state.user = {};  
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(fetchUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { clearUser } = userSlice.actions;
export default userSlice.reducer;

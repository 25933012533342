import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import userReducer from './userSlice';
import beatsReducer from './beatsSlice';
import favouritesReducer from './favouriteSlice';
import cartReducer from './cartSlice';
import stemCartReducer from './stemsCartSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    beats:beatsReducer,
    favorites:favouritesReducer,
    cart:cartReducer,
    stemscart:stemCartReducer,

  },
});

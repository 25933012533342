import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

const InviteUserModal = ({ isOpen, onClose }) => {
    const [email, setEmail] = useState('');
    const [credits, setCredits] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log('Inviting user with email:', email, 'and credits:', credits);
        onClose(); // Close the modal after submission
    };

    if (!isOpen) return null;

    return (
        <div className="absolute inset-0 flex items-center z-50 justify-center text-white bg-black bg-opacity-50 backdrop-blur-sm">
            <div className="p-6 bg-[#141414] rounded-lg w-full max-w-sm">
                <div className="flex py-3 gap-8 items-center justify-center">
                    <h2 className="text-xl font-bold text-center">Invite User</h2>
                    <FaTimes onClick={onClose} className='cursor-pointer relative lg:left-24 flex justify-end text-end text-white' />
                </div>
                <form onSubmit={handleSubmit} className='flex flex-col gap-2'>
                    <div className="">
                        <label htmlFor="email" className="block text-sm font-medium p-1">Email</label>
                        <input
                            type="email"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full border text-black rounded-md p-2"
                            required
                        />
                    </div>
                    <div className="">
                        <label htmlFor="credits" className="block text-sm font-medium p-1">Credits</label>
                        <input
                            type="text"
                            id="credits"
                            value={credits}
                            onChange={(e) => setCredits(e.target.value)}
                            className="w-full border border-gray-300 rounded-md p-2"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-between py-2">
                        <h1>Assign free credits</h1>
                        <input type="checkbox" className="h-4 w-4 text-red-500 focus:ring-red-400 border-gray-300 accent-[#FF3E41] rounded" />
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600"
                    >
                        Invite
                    </button>
                </form>
            </div>
        </div>
    );
};

export default InviteUserModal;

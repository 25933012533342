import React from 'react'
import Layout from '../layout/Layout'
import Section from '../components/Section'

export const ComingSoon = () => {
  return (
    <Layout>
        <Section>
        <div className='flex justify-center items-center mx-auto h-[100vh]'>
         <p className='lg:text-5xl md:text-2xl text-xl  font-semibold'>
            Coming Soon
        </p>
        </div>
    </Section>
    </Layout>
  )
}

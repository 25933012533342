import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backend_url } from '../libs/data';


export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async ({ userId, beatId, isLoggedIn }, { rejectWithValue }) => {
    if (isLoggedIn) {
      try {
        await axios.post(`${backend_url}/api/cart`, { userId, beatId });
        return beatId;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    } else {
      return beatId;
    }
  }
);

// Remove from cart
export const removeFromCart = createAsyncThunk(
  'cart/removeFromCart',
  async ({ userId, beatId, isLoggedIn }, { rejectWithValue }) => {
    if (isLoggedIn) {
      try {
        await axios.delete(`${backend_url}/api/cart`, { data: { userId, beatId } });
        return beatId;
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    } else {
      return beatId;
    }
  }
);

export const fetchCartItems = createAsyncThunk(
    'cart/fetchCartItems',
    async (userId, { rejectWithValue }) => {
      try {
        const response = await axios.get(`${backend_url}/api/cart/${userId}`);
        console.log('Fetched cart items:', response.data.cartItems);
        return response.data.cartItems; 
      } catch (error) {
        return rejectWithValue(error.response.data);
      }
    }
  );

  const cartSlice = createSlice({
    name: 'cart',
    initialState: {
      cartItems: [],  
      status: 'idle', 
      error: null,  
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchCartItems.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(fetchCartItems.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.cartItems = action.payload; 
        })
        .addCase(fetchCartItems.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.payload; 
        })
        .addCase(addToCart.fulfilled, (state, action) => {
          if (!state.cartItems.some((item) => item._id === action.payload)) {
            state.cartItems.push({ _id: action.payload });
          }
        })
        .addCase(removeFromCart.fulfilled, (state, action) => {
          state.cartItems = state.cartItems.filter((item) => item._id !== action.payload);
        })
        .addCase(addToCart.rejected, (state, action) => {
          state.error = action.payload;
        })
        .addCase(removeFromCart.rejected, (state, action) => {
          state.error = action.payload;
        });
    },
  });
  
  export default cartSlice.reducer;
  
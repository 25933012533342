
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { backend_url } from '../libs/data';
import { useDispatch } from 'react-redux';

export const addFavorite = createAsyncThunk(
    'favorites/addFavorite',
    async ({ userId, beatId }, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${backend_url}/api/favorites`, { userId, beatId });
           
            return userId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const removeFavorite = createAsyncThunk(
    'favorites/removeFavorite',
    async ({ userId, beatId }, { rejectWithValue }) => {
        try {
            const response = await axios.delete(`${backend_url}/api/favorites`, { data: { userId, beatId } });
            return userId;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const fetchFavorites = createAsyncThunk(
    'favorites/fetchFavorites',
    async (userId, { rejectWithValue }) => {
        try {
            const response = await axios.get(`${backend_url}/api/favorites/${userId}`);
            return response.data.beats;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const favoritesSlice = createSlice({
    name: 'favorites',
    initialState: {
        favorites: [],
        status: 'idle',
        error: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addFavorite.fulfilled, (state, action) => {
                state.favorites.push(action.payload);
            })
            .addCase(removeFavorite.fulfilled, (state, action) => {
                state.favorites = state.favorites.filter(fav => fav._id !== action.payload);
            })
            .addCase(fetchFavorites.fulfilled, (state, action) => {
                state.favorites = action.payload;
            })
            .addCase(addFavorite.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(removeFavorite.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(fetchFavorites.rejected, (state, action) => {
                state.error = action.payload;
            });
    }
});

export default favoritesSlice.reducer;

import React, { useEffect, useState } from 'react';
import musicIcon from '../assets/musicIcon.svg';
import download from '../assets/download.svg';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, fetchFavorites, removeFavorite } from '../redux/favouriteSlice'
import { fetchUser } from '../redux/userSlice';
import toast from 'react-hot-toast';
import PricingModel from './PricingModel';
import { useNavigate } from 'react-router-dom';
import { HiMusicNote } from 'react-icons/hi';
import { fetchStemsCartItems } from '../redux/stemsCartSlice';
import { backend_url } from '../libs/data';
import spinner from '../assets/spinner.svg'
import axios from 'axios';
const MusicItem = ({ number,scrollToTop,selectedBeat,beatNo,setSelectedBeat,isOpen,setIsOpen, beatTitle,setIsPlaying,isPlaying, bpm, tags,beat,musicIndex, section,beatId,userId,favorites,user,setMusicIndex,isSelected, onSelect  }) => {
    
    const showHeart = section !== 'purchase History';
    const showDownload = section !== 'purchase History';
    const dispatch = useDispatch()
    const isFavorite = favorites?.some((fav) => fav._id === beatId);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const [loading,setLoading] = useState(false)
   
    const navigate = useNavigate()


    const [isAllow,setIsAllow] = useState(false)
    const [isAllowStems,setIsAllowStems] = useState(false)

    useEffect(() => {
        if (userId) {
            dispatch(fetchStemsCartItems(userId))
        }
    }, [dispatch, user]);

  const HandleClosePricing = () => {
        setIsOpen(false);
        setSelectedBeat(null);
    };
    const handleFavoriteToggle = () => {
        if(!isAuthenticated)
        {
            navigate('/login')
            return
        }
        if (isFavorite) {
            dispatch(removeFavorite({ userId, beatId }))
                .then(() => {
                    dispatch(fetchFavorites(userId));
                    toast.success(`${beatTitle} removed from favorites`);
                })
                .catch((error) => {
                    toast.error('Failed to remove from favorites');
                });
        } else {
            dispatch(addFavorite({ userId, beatId }))
                .then(() => {
                    dispatch(fetchFavorites(userId));
                    toast.success(`${beatTitle} added to favorites`);
                })
                .catch((error) => {
                    toast.error('Failed to add to favorites');
                });
        }
    };

    const HandleDownload = ()=>{
        setIsOpen(true)
         setSelectedBeat(beat)
    
    }

    const GetAllowInfo = async (userId, beatsId) => {
        if(!isAuthenticated)
            {
                navigate('/login')
                return
            }
        try {
            setLoading(true);
            const res = await axios.get(`${backend_url}/api/checkCreditDownload/${userId}/${beatsId}`);
            
            if (res.data?.isAllowDownload) {
                setIsAllow(true);
            } else {
                setIsAllow(false);
            }
    
            if (res.data?.isAllowStems) {
                setIsAllowStems(true);
            } else {
                setIsAllowStems(false);
            }

            HandleDownload()
        } catch (error) {
            if (error.response?.status === 404) {
                console.warn("Beat not found in user downloads.");
              //  toast.error("Beat not found. Please try a different beat.");
            } else {
                console.error("An error occurred:", error.message);
                // toast.error("An error occurred while checking download permissions.");
            }
        } finally {
            setLoading(false);
        }
    };
    
  

    return (
        <div className="flex py-1 items-center justify-between text-white rounded-lg lg:px-0 px-10">
        <div className="grid lg:grid-cols-12 py-3 lg:bg-black gap-3 rounded-lg grid-cols-1 w-full items-center">
            <div className="text-lg lg:col-span-1 py-2 md:bg-[#141414] lg:h-20 lg:w-[4vw] bg-[#FF3E41] justify-center rounded-lg flex flex-col items-center font-bold">{beatNo}</div>
            
            <div className={`lg:flex-row flex-col flex lg:gap-[7%] gap-2 items-center justify-center w-full ${isSelected ? 'bg-gray-800 scale-105' : 'bg-[#141414]'} py-4 duration-300 px-4 rounded-lg lg:col-span-11`}
                onClick={() => {
                    onSelect();
                    setMusicIndex(number - 1);
                }}
            >
                <div className="lg:w-[10%] flex items-center justify-center">
                    <div className="flex items-center justify-center cursor-pointer w-10 h-10 rounded-full bg-[#FF3E41]" onClick={() => setIsPlaying(true)}>
                        {isPlaying && isSelected ? (
                            <div className="equalizer">
                                <div className="bar"></div>
                                <div className="bar"></div>
                                <div className="bar"></div>
                                <div className="bar"></div>
                            </div>
                        ) : (
                            <HiMusicNote size={20} />
                        )}
                    </div>
                </div>
    
                <div className="flex items-center gap-3 lg:w-[20%] min-w-0 lg:justify-start justify-center" onClick={() => setIsPlaying(true)}>
                    <div className="font-bold whitespace-nowrap">Name</div>
                    <div className="text-[15px] lg:truncate">{beatTitle}</div>
                </div>
    
                <div className="flex gap-3 lg:w-[10%] min-w-0" onClick={() => setIsPlaying(true)}>
                    <div className="font-bold">BPM</div>
                    <div>{bpm}</div>
                </div>
    
                <div className="flex md:flex-row flex-col gap-3 lg:w-[30%] w-full min-w-0 items-center lg:justify-start justify-center" onClick={() => setIsPlaying(true)}>
                    <div className="font-bold text-left whitespace-nowrap">Tags</div>
                    <div className="flex gap-2 w-full overflow-hidden">
                        {tags.map((tag, index) => (
                            <span key={index} className="bg-black px-2 py-1 rounded-md text-sm truncate">
                                {tag}
                            </span>
                        ))}
                    </div>
                </div>
    
                {showHeart && (
                    <div 
                        className="hover:scale-110 hover:transition-transform duration-200 cursor-pointer lg:w-[10%]" 
                        onClick={handleFavoriteToggle}
                    >
                        {isFavorite ? <IoHeart color='#FF3E41' size={30} /> : <IoHeartOutline color='#FF3E41' size={30} />}
                    </div>
                )}
    
                {showDownload && (
                    <div className="flex items-center lg:w-[20%]">
                        <button className="bg-red-500 hover:scale-110 hover:transition-transform duration-200 py-1 px-6 rounded-md" onClick={()=> GetAllowInfo(userId,beatId)}>
                            {loading ? ( 
                                <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="currentColor" d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
                            ) : (
                            <img src={download} alt='' />
                                )}
                        </button>
                    </div>
                )}
            </div>
        </div>
    
        <PricingModel isOpen={isOpen} isAllow={isAllow} loading={loading} setLoading={setLoading} isAllowStems={isAllowStems} setIsAllow={setIsAllow} setIsAllowStems={setIsAllowStems} onClose={HandleClosePricing} user={user} beat={selectedBeat} scrollToTop={scrollToTop}/>
    </div>
    
    );
};

const MusicList = ({ section,beats,exLength,setMusicIndex,isPlaying,musicIndex,scrollToTop,setIsPlaying,selectedBeat,setSelectedBeat,isOpen,setIsOpen }) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const favorites = useSelector((state) => state.favorites.favorites);
    const [selectedIndex, setSelectedIndex] = useState(null);

    useEffect(() => {
        if (user?._id) {
            dispatch(fetchFavorites(user._id));
        }
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    const handleSelect = (index, beat) => {
        console.log("bea",beat.beatTitle)
        console.log("ind",index+exLength)
        setSelectedIndex(index+exLength);
        setMusicIndex(index+exLength); 
        setSelectedBeat(beat); 
      //  setIsPlaying(true); 
    };
    
    return (
        <div className="p-5">
            {beats?.map((item, index) => (
                <MusicItem key={index} 
                    {...item} 
                    isSelected={musicIndex === index + exLength}
                    onSelect={() => handleSelect(index, item)}
                    musicIndex={musicIndex}
                    setIsPlaying={setIsPlaying}
                    scrollToTop={scrollToTop}
                    selectedBeat={selectedBeat} 
                    setSelectedBeat={setSelectedBeat} 
                    isOpen={isOpen} 
                    setIsOpen={setIsOpen}
                    isPlaying={isPlaying} 
                    setMusicIndex={setMusicIndex} 
                    beat={item} 
                    section={section} 
                    number={index + exLength+ 1} 
                    beatNo={beats.length - index}
                    beatId={item._id} 
                    favorites={favorites} 
                    userId={user?._id} 
                    user={user}
                />
            ))}
        </div>
    );
};


export default MusicList;

import React from 'react'
import Layout from '../layout/Layout'
import lines from '../assets/lines.svg'
import outlineheart from '../assets/outlineheart.svg'
import circlePlay from '../assets/circlePlay.svg'
import Plus from '../assets/Plus.svg'
import PricingCards from '../components/PricingCard'
import MusicList from '../components/MusicList'
import Section from '../components/Section'

const Beats = () => {
    return (
        <Layout>
            <Section>
                <div className="lg:px-10">
                    <div className="lg:px-5 py-8">
                        <div className="bg-[#141414] mx-auto w-3/4 lg:w-full px-5 py-3 rounded-md">
                            <h1 className='text-white text-xl font-bold'>Information</h1>
                            <div className="flex gap-4 items-center  w-full bg-[#141414] py-3 rounded-lg">
                                <div className="lg:flex items-center lg:gap-16 justify-between">
                                    <div className='flex items-center gap-3'>
                                        <div className="font-bold">Song name</div>
                                        <div className='text-[15px]'>blah blah</div>
                                    </div>
                                    <div className='flex gap-3'>
                                        <div className="font-bold">Artist</div>
                                        <div>Artist</div>
                                    </div>
                                    <div className='flex gap-3'>
                                        <div className="font-bold">Genre</div>
                                        <div>Pop</div>
                                    </div>
                                    <div className='flex gap-3'>
                                        <div className="font-bold">BPM</div>
                                        <div className="">120</div>
                                    </div>
                                    <div className='flex gap-3'>
                                        <div className="font-bold">Released</div>
                                        <div className="">123</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-3 px-5 items-center justify-center lg:justify-end py-7">
                        <h1 className='text-base font-medium'>$39.99</h1>
                        <button className="bg-[#FF3E41] hover:scale-110 hover:transition-transform duration-200 px-3 py-2 rounded-md">Add to cart</button>
                    </div>

                    <div className="flex lg:px-0 px-4 justify-center py-7">
                        <img src={lines} alt="" />
                    </div>
                    <div className="flex justify-center items-center gap-4">
                        <img src={outlineheart} alt="" />
                        <img src={circlePlay} alt="" />
                        <img src={Plus} alt="" />
                    </div>

                    <div className="lg:px-0 px-4">
                        {/* <PricingCards /> */}
                    </div>

                    <div className="">
                        <h1 className='text-white py-8 text-center text-4xl font-bold'>Other Tracks by Artist</h1>
                    </div>

                    <MusicList />
                </div>
            </Section>
        </Layout>
    )
}

export default Beats
import React from 'react'

const MixingForm = () => {
    return (
        <>
            <div className="py-12">
                <div className="bg-[#141414] rounded-lg py-6 px-5">
                    <h1 className='text-xl font-medium text-center'>Ready to get started</h1>

                    <div className="">
                        <form className="space-y-3 w-full">
                            <div>
                                <label className="block p-1 text-white">Full Name *</label>
                                <input type="text" className="w-full text-black px-2 focus:outline-none py-3 border-2 border-opacity-60 border-[#D0D0D0] rounded-md " placeholder="Enter Your Full Name" />
                            </div>
                            <div>
                                <label className="block p-1  text-white">Email *</label>
                                <input type="email" className="w-full text-black px-2 focus:outline-none py-3 border-2 border-opacity-60 border-[#D0D0D0] rounded-md " placeholder="Enter Your Email" />
                            </div>
                            <div>
                                <label className="block p-1  text-white">Project Details</label>
                                <input type="text" className="w-full text-black px-2 focus:outline-none py-3 border-2 border-opacity-60 border-[#D0D0D0] rounded-md " placeholder="Enter Your project details" />
                            </div>
                            <div className="flex justify-center">
                                <button className=" bg-red-500 px-5 text-white py-2 rounded-lg hover:bg-red-600 transition duration-200"> Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </>
    )
}

export default MixingForm
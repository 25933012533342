import React, { useState, useRef, useEffect } from 'react';
import toast from 'react-hot-toast';
import { backend_url } from '../libs/data';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const OTPVerification = () => {
  const [otp, setOtp] = useState(new Array(5).fill(''));
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [loadingresend, setLoadingresend] = useState(false);
  const [timer, setTimer] = useState(60); 
  const [canResend, setCanResend] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
      return () => clearInterval(interval); 
    } else {
      setCanResend(true); 
    }
  }, [timer]);

  const handleChange = (element, index) => {
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);
    if (element.value && index < 4) {
      inputRefs.current[index + 1].focus();
    }
    if (!element.value && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = otp.join('');
    if (otpCode.length !== 5) {
      toast.error("Fill in the code");
      return;
    }

    try {
      setLoading(true);
      const otpString = otp.join('');
      const email = localStorage.getItem("email");
      await axios.post(`${backend_url}/verifyotp`, {
        email, otp: otpString
      });
      toast.success("OTP Verified successfully");
      navigate('/changepassword');
    } catch (error) {
      const errorMessage = error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleResendOTP = async () => {
    try{
        const email = localStorage.getItem('email')
        setLoadingresend(true)
        await axios.post(`${backend_url}/sendotp`,{
          email
        })    
       toast.success("OTP resent successfully")
       setTimer(60)
       setCanResend(false);
       }catch(error){
        const errorMessage = error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
        toast.error(errorMessage);
    
       }finally{
        setLoadingresend(false)
       }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 ">
      <div className='bg-white shadow-lg rounded-lg p-8 w-full max-w-sm'>
        <h1 className="text-2xl font-bold mb-4 text-center">Enter OTP</h1>
        <form onSubmit={handleSubmit} className="w-80 flex flex-col gap-4 ">
          <div className="flex gap-2 justify-center">
            {otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                className="w-12 h-12 text-center border focus:outline-[#FF3E41] border-gray-300 rounded text-lg"
                value={digit}
                onChange={(e) => handleChange(e.target, index)}
                ref={(el) => (inputRefs.current[index] = el)} 
              />
            ))}
          </div>
          <button
            type="submit"
            className="p-2 bg-red-500 hover:bg-red-600 text-white rounded "
          >
            {loading ? "Verifying OTP..." : "Verify OTP"}
          </button>
        </form>
        <div className="mt-2 flex flex-col items-center">
          <p className="text-sm text-gray-500">{canResend ? "You can now resend the OTP." : `Resend OTP in ${timer}s`}</p>
          <button
            onClick={handleResendOTP}
            disabled={!canResend}
            className={` p-1 text-[#FF3E41] rounded ${!canResend ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {loadingresend ? "Resending OTP..." : "Resend OTP"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OTPVerification;

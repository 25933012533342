import React from 'react'
import Layout from '../layout/Layout'
import MixingForm from '../components/MixingForm'
import headphone from '../assets/headphone.svg'
import disk from '../assets/disk.svg'
import guitar from '../assets/guitar.svg'
import glasses from '../assets/glasses.svg'
import Section from '../components/Section'

const Artworks = () => {
    return (
        <>
            <Layout>
                <Section>
                    <div className="lg:px-16 px-2">
                        <div className="py-7">
                            <h1 className='text-center lg:leading-[3.8rem] py-3 text-3xl lg:text-6xl font-medium'>
                                Professional Artworks for Your Projects
                            </h1>
                            <p className='lg:text-center tracking-wider lg:px-8 px-3 font-normal text-lg lg:text-4xl py-7'>
                                Elevate your music with stunning, custom artwork crafted by our talented artists. Perfect for album covers, promotional materials, and more, our designs ensure your music stands out and captures your unique style.
                            </p>
                        </div>

                        <div className="py-5">
                            <div className="py-12">
                                <h1 className='text-4xl text-center font-bold'>Artworks</h1>
                            </div>
                            <div className="bg-[#141414] rounded-lg p-4">
                                <div className="grid md:grid-cols-4 gap-6">
                                    <img src={headphone} alt="Headphone" className="w-full h-auto" />
                                    <img src={disk} alt="record" className="w-full h-auto" />
                                    <img src={guitar} alt="Guitar" className="w-full h-auto " />
                                    <img src={glasses} alt="Woman" className="w-full h-auto " />
                                    <img src={headphone} alt="Headphone" className="w-full h-auto " />
                                    <img src={disk} alt="Record" className="w-full h-auto " />
                                    <img src={guitar} alt="Guitar" className="w-full h-auto " />
                                    <img src={glasses} alt="Woman" className="w-full h-auto" />
                                </div>
                            </div>
                        </div>


                        <MixingForm />
                    </div>
                </Section>
            </Layout>
        </>
    )
}

export default Artworks
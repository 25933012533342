import React, { useEffect, useState } from 'react'
import Layout from '../layout/Layout'
import Section from '../components/Section'
import { useDispatch, useSelector } from 'react-redux';
import moon from '../assets/moon.png'
import { fetchCartItems, removeFromCart } from '../redux/cartSlice';
import { fetchUser } from '../redux/userSlice';
import { MdOutlineDelete } from 'react-icons/md';
import axios from 'axios';
import { backend_url } from '../libs/data';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { fetchStemsCartItems, removeFromStemsCart } from '../redux/stemsCartSlice';

export const Cart = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { user } = useSelector((state) => state.user);
    const cartItems = useSelector((state) => state.cart.cartItems);
    const stemsCartItems = useSelector((state) => state.stemscart.cartItems);
    const [selected,setSelected] = useState([])
    const [stemsSelected,setStemsSelected] = useState([])
    const isLoggedIn = useSelector((state) => state.auth.isAuthenticated);
    const isEmpty = cartItems===undefined
    const isStemsEmpty = stemsCartItems===undefined
    
    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    useEffect(() => {
        if (user?._id) {
            dispatch(fetchCartItems(user._id));
        }
    }, [dispatch, user?._id]);
    useEffect(() => {
        if (user?._id) {
            dispatch(fetchStemsCartItems(user._id))
        }
    }, [dispatch, user?._id]);

   
    const HandlePay = async ()=>{
        if(!isLoggedIn)
            {
                navigate('/login')
                return
            }
            if (selected.length === 0) {
                toast.error("Please select at least one item to purchase.");
                return;
            }
            try {
                const selectedItems = selected.map(index => cartItems[index]);
    
                const res = await axios.post(`${backend_url}/create-checkout-session`, {
                    beatDetails: selectedItems.map(item => ({
                        price: item.price,
                        beatName: item.beatTitle,
                        beatId: item._id,
                    })),
                    email: user.email,
                    sessionType: "beatPurchase",
                    userId: user._id
                });
    
                window.location.replace(res.data.session.url);
            }
            catch(error)
            {
                const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                toast.error(errorMessage);
    
            }
    }
    const HandlePayStems = async ()=>{
        if(!isLoggedIn)
            {
                navigate('/login')
                return
            }
            if (stemsSelected.length === 0) {
                toast.error("Please select at least one item to purchase.");
                return;
            }
            try {
                const selectedItems = stemsSelected.map(index => stemsCartItems[index]);
    
                const res = await axios.post(`${backend_url}/create-checkout-session-stems`, {
                    beatDetails: selectedItems.map((item, index) => ({
                        price: 25,
                        beatName: item.beatTitle,
                        beatId: item._id,
                      })),
                    email: user.email,
                    sessionType: "stems",
                    userId: user._id
                });
    
                window.location.replace(res.data.session.url);
            }
            catch(error)
            {
                const errorMessage = error.response && error.response.data.message
                ? error.response.data.message
                : error.message;
                toast.error(errorMessage);
    
            }
    }
    const handleRemoveFromCart = (index) => {
        dispatch(removeFromCart({ userId:user._id, beatId: cartItems[index]._id, isLoggedIn }))
        .then(() => {

            toast.success(`${cartItems[index].beatTitle} Removed from Cart`);
            setSelected(selected.filter(i => i !== index));
        })
        .catch((error) => {
            toast.error('Failed to removed from Cart');
        });
       
    };
    const handleRemoveStemsFromCart = (index) => {
        dispatch(removeFromStemsCart({ userId:user._id, beatId: stemsCartItems[index]._id, isLoggedIn }))
        .then(() => {
            toast.success(`${stemsCartItems[index].beatTitle} Removed from Cart`);
            setStemsSelected(stemsSelected.filter(i => i !== index));
        })
        .catch((error) => {
            toast.error('Failed to removed from Cart');
        });
       
    };
    const HandleSelect = (index)=>{
        setStemsSelected([])
        if(selected.includes(index))
        {
            setSelected(
             selected.filter(i => i!=index)
            )
        }
        else{
            setSelected([
                ...selected,
                index
            ])
        }
      
    }
    const HandleStemsSelect = (index)=>{
        setSelected([])
        if(stemsSelected.includes(index))
        {
            setStemsSelected(
                stemsSelected.filter(i => i!=index)
            )
        }
        else{
            setStemsSelected([
                ...stemsSelected,
                index
            ])
        }
      
    }

    const totalPrice = selected.reduce((item,index)=>{
        const price = parseFloat(cartItems[index]?.price);
        return item + price;

    },0)
    

  return (
    <Layout>
        <Section>
            <div className='px-10'>
                <p className='text-3xl font-semibold'>Cart</p>
                {
                    !isEmpty ? (
                        <div className=' grid md:grid-cols-3 grid-cols-1 pt-10 gap-8'>
                        <div className='flex flex-col gap-7 col-span-2'>
                          {cartItems && cartItems.map((item,index)=>{
                              return(
                              <div key={index} className='flex items-center gap-2 cursor-pointer ' onClick={()=>{HandleSelect(index)}}>
                               <div className={`h-[18px] w-[18px] border-[1.5px] border-[#FFFFFF] rounded-full ${selected.includes(index) ? "bg-[#FF3E41]" : ""}`}></div>
                                  <div className='bg-[#141414] shadow-[0px_4px_13px_0px_rgba(0,0,0,0.6)] p-2 rounded-2xl w-[100%]'>
                                      <div className='flex items-center justify-between'>
                                          <div className='flex items-center gap-5'>
                                              <div className='rounded-xl object-cover'>
                                                  <img src={moon} alt="" />
                                              </div>
                                              <div className='flex flex-col gap-2'>
                                                  <p className='font-medium text-white text-lg'>{item.beatTitle}</p>
                                                  <p className='font-medium text-white text-lg'>MP3</p>
                                                  <p className='font-medium text-[#9F9F9F] text-lg'>{item.price} $</p>
                                              </div>
                                          </div>
                                          <div className='right cursor-pointer pr-5' onClick={()=>{
                                            handleRemoveFromCart(index)
                                          }}>
                                          <MdOutlineDelete color='white' size={25} />
                                          </div>
      
                                      </div>
      
                                  </div>
      
                              </div>
                          )})}
                          </div>
      
                         {
                            !isEmpty && selected.length>0 ? (
                                <div className='bg-[#141414] rounded-2xl h-[300px] p-10 col-span-1'>
                                <div className='flex flex-col gap-5'>
                                    <p className='text-[#FFFFFF] font-bold text-2xl text-center'>Order Summary</p>
                                    <div className='flex justify-between items-center px-7'>
                                        <div className='flex items-start flex-col gap-2'>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>Beat</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>Subtotal</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>Total</p>
                                        </div>
                                        <div className='flex items-start flex-col gap-2'>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>{stemsSelected.length > 0 ? cartItems[selected[0]]?.beatTitle : '' } and {selected.length - 1} more</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>{totalPrice}$</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>{totalPrice}$</p>
                                        </div>   
                                    </div>
                                <button className='bg-[#FF3E41] rounded-2xl p-2' onClick={()=>{
                                    HandlePay()
                                }}>Pay Now</button>
        
                                </div>
                               
                            </div>

                            ): (
                                ""
                            )
                         }
                         {
                            !isStemsEmpty && stemsSelected.length>0 ? (
                                <div className='bg-[#141414] rounded-2xl h-[300px] p-10 col-span-1'>
                                <div className='flex flex-col gap-5'>
                                    <p className='text-[#FFFFFF] font-bold text-2xl text-center'>Order Summary</p>
                                    <div className='flex justify-between items-center px-7'>
                                        <div className='flex items-start flex-col gap-2'>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>Beat Stems</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>Subtotal</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>Total</p>
                                        </div>
                                        <div className='flex items-start flex-col gap-2'>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>{stemsSelected.length > 0 ? stemsCartItems[stemsSelected[0]]?.beatTitle : '' } and {stemsSelected.length - 1} more</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>{25 * stemsSelected.length}$</p>
                                        <p className='text-base text-[#9F9F9F] font-semibold'>{25 * stemsSelected.length}$</p>
                                        </div>   
                                    </div>
                                <button className='bg-[#FF3E41] rounded-2xl p-2' onClick={()=>{
                                    if(selected.length>0)
                                    {
                                        HandlePay()
                                    }else{
                                        HandlePayStems()
                                    }
                                }}>Pay Now</button>
        
                                </div>
                               
                            </div>

                            ): (
                                ""
                            )
                         }
                      
      
                      </div>

                    ):
                    (
                        ""
                    )
                }
                <p className='text-3xl font-semibold pt-5'>Stems Cart</p>
                {
                    !isStemsEmpty ? (
                        <div className=' grid md:grid-cols-3 grid-cols-1 pt-10 gap-8'>
                        <div className='flex flex-col gap-7 col-span-2'>
                          {stemsCartItems && stemsCartItems.map((item,index)=>{
                              return(
                              <div key={index} className='flex items-center gap-2 cursor-pointer ' onClick={()=>{HandleStemsSelect(index)}}>
                               <div className={`h-[18px] w-[18px] border-[1.5px] border-[#FFFFFF] rounded-full ${stemsSelected.includes(index) ? "bg-[#FF3E41]" : ""}`}></div>
                                  <div className='bg-[#141414] shadow-[0px_4px_13px_0px_rgba(0,0,0,0.6)] p-2 rounded-2xl w-[100%]'>
                                      <div className='flex items-center justify-between'>
                                          <div className='flex items-center gap-5'>
                                              <div className='rounded-xl object-cover'>
                                                  <img src={moon} alt="" />
                                              </div>
                                              <div className='flex flex-col gap-2'>
                                                  <p className='font-medium text-white text-lg'>{item.beatTitle}</p>
                                                  <p className='font-medium text-white text-lg'>Stems</p>
                                                  <p className='font-medium text-[#9F9F9F] text-lg'>25$</p>
                                              </div>
                                          </div>
                                          <div className='right cursor-pointer pr-5' onClick={()=>{
                                            handleRemoveStemsFromCart(index)
                                          }}>
                                          <MdOutlineDelete color='white' size={25} />
                                          </div>
      
                                      </div>
      
                                  </div>
      
                              </div>
                          )})}
                          </div>
          
                      </div>

                    ):
                    (
                        ""
                    )
                }

                
             
            </div>
        </Section>
    </Layout>
  )
}

import React, { useEffect, useState } from 'react';
import UserLayout from '../../layout/UserLayout';
import MusicList from '../../components/MusicList';
import Section from '../../components/Section';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from '../../redux/userSlice';
import axios from 'axios';
import { backend_url } from '../../libs/data';
import { LoaderComp } from '../../UI/Loader';

const UserDashboard = () => {
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [loading,setLoading] = useState(false)
    const [recentPurchases,setRecentPurchases] = useState('')

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    const GetRecentPurchases = async(id)=>{
        try{
            setLoading(true)
            const res = await axios.get(`${backend_url}/api/getrecentpurchases/${id}`)
            setRecentPurchases(res.data)
        }
        catch(error)
        {
            console.log(error)
        }
        finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        if(user && user._id)
        {
            GetRecentPurchases(user._id)
        }
    },[user,user._id])
   
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            console.error('Invalid date:', dateString);
            return 'Invalid Date'; 
        }
    
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }


    return (
        <>
            <UserLayout>
                <Section>
                    {
                        loading ? (
                            <LoaderComp/>
                        ) : (
                            <div className="md:px-10">
                            <div className="px-5 py-3">
                                <h1 className='text-white text-2xl font-bold py-8'>Dashboard</h1>
                                {/* <p className='text-[#9F9F9F] text-xl'>Recent Activity</p> */}
                            </div>
                            <div className="">
                                <p className='text-lg px-5 text-[#9F9F9F]'>Purchase History</p>
                               <div className='flex flex-col gap-5 pt-5 items-center justify-between'>
                                   {recentPurchases ? 
                                      recentPurchases.map((item,index)=>{
                                        return (
                                            <div key={index} className='bg-[#141414] rounded-lg w-full grid lg:grid-cols-3 gap-5 md:grid-cols-2 grid-cols-1 lg:p-8 p-5'>
                                               <p>Purchase Type:  <span className='bg-[#FF3E41] rounded-md p-2'>{item.sessionType}</span></p>
                                               <p>Price: <span className='font-bold'>{item.price}$</span></p>
                                               <p>Purchase Date: {formatDate(item.paymentDate)}</p>
    
                                            </div>
                                        )
                                    })
                                    : (
                                        <p className='lg:text-2xl text-xl font-semibold'>
                                            No Purchases Yet
                                        </p>
                                    )}
                               </div>
                            </div>
                            {/* <div className="pt-5">
                                <p className='text-lg px-5 text-[#9F9F9F]'>Recent Favourites</p>
                                <MusicList section="favourites" />
                            </div> */}
                        </div>
                        )
                    }
                   
                </Section>
            </UserLayout>
        </>
    );
};

export default UserDashboard;

import React, { useEffect, useState } from 'react';
import Layout from '../layout/Layout';
import Cards from '../components/Cards';
import MusicList from '../components/MusicList';
import MusicPlayer from '../components/MusicPlayer';
import search from '../assets/search.svg';
import Section from '../components/Section';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBeats } from '../redux/beatsSlice';
import { fetchUser } from '../redux/userSlice';
import { fetchFavorites } from '../redux/favouriteSlice';
import { LoaderComp } from '../UI/Loader';
import { useNavigate } from 'react-router-dom';
import { fetchCartItems } from '../redux/cartSlice';

const LandingPage = () => {
    const dispatch = useDispatch();
    const { beats, loading } = useSelector((state) => state.beats);
    const favorites = useSelector((state) => state.favorites.favorites);
    const { user } = useSelector((state) => state.user);
    const [musicIndex, setMusicIndex] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedBeat, setSelectedBeat] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [tracks, setTracks] = useState([]); // Original beats list for the player
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            dispatch(fetchUser());
        }
    }, [dispatch, user]);

    useEffect(() => {
        dispatch(fetchBeats());
    }, [dispatch]);

    useEffect(() => {
        if (user?._id) {
            dispatch(fetchCartItems(user._id));
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (user && user?.hasPasswordChanged === false) {
            navigate('/account-setting');
        }
    }, [user, navigate]);

    useEffect(() => {
        if (beats.length > 0) {
            setTracks(beats); 
        }
    }, [beats]);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    if (loading) {
        return <LoaderComp />;
    }

    // Use filtered beats based on search query for the handleSelect function
    const handleSelect = (index) => {
        const beat = filteredBeats[index];
        setSelectedIndex(index);
        setMusicIndex(index);
        setSelectedBeat(beat);
        setIsPlaying(true);
    };

    // Filter and sort beats
    const filteredBeats = searchQuery
        ? beats.filter(beat =>
            beat.beatTitle.toLowerCase().includes(searchQuery.toLowerCase()) ||
            (beat.tags && beat.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase())))
        )
        : beats;

    const SortBeats = (beats) => {
        return [...beats].sort((a, b) => {
            if (a.beatType === 'Exclusive' && b.beatType !== 'Exclusive') return -1;
            if (a.beatType !== 'Exclusive' && b.beatType === 'Exclusive') return 1;
            return 0;
        });
    };

    const exLength = filteredBeats ? filteredBeats.filter(beat => beat?.beatType === "Exclusive").length : 0;

    return (
        <Layout>
            <Section>
                <div className="min-h-screen md:px-10">
                    <div className='flex items-center justify-center py-5'>
                        <div className="flex items-center justify-center px-3 rounded-md bg-[#141414] relative w-full lg:w-1/2">
                            <img src={search} alt="Search" className='' />
                            <input
                                type="text"
                                placeholder="Search for beats"
                                className="py-3 rounded bg-transparent w-full px-3 focus:outline-none text-white"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <button className="bg-red-600 absolute hover:scale-110 hover:transition-transform duration-200 lg:right-6 right-2 lg:px-4 px-1 py-1 text-white rounded-lg">Search</button>
                        </div>
                    </div>
                    <div className="py-6 px-5">
                        <h1 className='text-white md:text-[46px] py-4 text-2xl font-bold text-center'>NEW EXCLUSIVE</h1>
                        <p className='text-[#9F9F9F] md:text-3xl text-lg text-center'>Be the first and only one to hop on these seats</p>
                    </div>
                    <div className="grid lg:grid-cols-4 grid-cols-1 px-5 py-9 gap-5">
                        {filteredBeats
                            .filter((beat) => beat.beatType === "Exclusive")
                            .map((item, index) => (
                                <Cards key={index} beat={item} userId={user?._id} user={user} selectedBeat={selectedBeat}
                                    setSelectedBeat={setSelectedBeat}
                                    musicIndex={musicIndex}
                                    setMusicIndex={setMusicIndex}
                                    isPlaying={isPlaying}
                                    setIsPlaying={setIsPlaying}
                                    isSelected={musicIndex === index}
                                    onSelect={() => handleSelect(index)}
                                    number={index} />
                            ))}
                    </div>

                    <div className="py-12">
                        <h1 className='text-4xl text-center font-bold'>LEASES</h1>
                    </div>
                    <div className="w-full">
                        <MusicList
                            exLength={exLength}
                            selectedBeat={selectedBeat}
                            setSelectedBeat={setSelectedBeat}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            beats={filteredBeats.filter((beat) => beat.beatType !== "Exclusive")}
                            scrollToTop={scrollToTop}
                            musicIndex={musicIndex}
                            setMusicIndex={setMusicIndex}
                            isPlaying={isPlaying}
                            setIsPlaying={setIsPlaying}
                        />
                    </div>
                </div>

                <MusicPlayer
                    selectedBeat={selectedBeat}
                    setSelectedBeat={setSelectedBeat}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    tracks={SortBeats(filteredBeats)}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    favorites={favorites}
                    userId={user?._id}
                    user={user}
                    scrollToTop={scrollToTop}
                    musicIndex={musicIndex}
                    setMusicIndex={setMusicIndex}
                />
            </Section>
        </Layout>
    );
};

export default LandingPage;


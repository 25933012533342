import React from 'react';
import Layout from '../layout/Layout';
import musical from '../assets/musical.svg';
import vip from '../assets/vip.svg';
import basket from '../assets/basket.svg';
import customer from '../assets/customer.svg';
import Section from '../components/Section';

const AboutUs = () => {

    const offers = [
        {
            title: 'Exclusive VIP Membership Perks',
            description: 'Unlock exclusive access to all non-exclusive beats and enjoy early access to new releases. Upgrade individual beats for stems or exclusive rights as needed.',
            icon: vip,
        },
        {
            title: 'High-Quality Beats Marketplace',
            description: 'Access a diverse and growing collection of hip-hop beats from talented producers worldwide, ensuring you find the perfect sound for your next hit.',
            icon: musical,
        },
        {
            title: 'Effortless Purchasing Experience',
            description: 'Enjoy a seamless and user-friendly shopping experience. Browse your favorite genres, make quick and secure purchases, and download beats with ease—all on one platform.',
            icon: basket,
        },
        {
            title: 'Personalized User Console',
            description: 'Manage your purchases, favorites, and personal details effortlessly, providing a personalized experience that caters to your specific needs.',
            icon: customer,
        },
    ];

    return (
        <Layout>
            <Section>
                <div className="lg:px-16 px-4">
                    <div className="py-7">
                        <h1 className='text-center lg:leading-[3.8rem] py-3 text-3xl lg:text-6xl font-medium'>
                            Our Mission
                        </h1>
                        <p className='lg:text-center tracking-wider md:leading-10 lg:px-8 md:px-3 text-lg lg:text-[35px] py-7'>
                            At [Your Company Name], we are dedicated to empowering artists by providing a platform that offers high-quality beats and professional services. Our mission is to support your creative journey, offering a comprehensive range of resources to help you produce, promote, and perfect your music.
                        </p>
                    </div>
                    <div className="bg-black text-white py-16">
                        <h2 className="text-center text-4xl font-bold mb-12">What We Offer</h2>
                        <div className="container mx-auto grid grid-cols-1 lg:px-32 lg:grid-cols-2 gap-14">
                            {offers.map((offer, index) => (
                                <div key={index} className="bg-[#1e1e1e] rounded-lg p-6 shadow-lg">
                                    <div className="flex flex-col gap-2">
                                        <img src={offer.icon} alt={offer.title} className="h-12 w-12 mb-4" />
                                        <h3 className="text-3xl font-semibold">{offer.title}</h3>
                                    </div>
                                    <p className="mt-4">{offer.description}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="py-7">
                        <h1 className='text-center lg:leading-[3.8rem] py-3 text-3xl lg:text-6xl font-medium'>
                            Our Vision
                        </h1>
                        <p className='lg:text-center tracking-wider lg:px-8 px-3 font-normal text-lg lg:text-4xl py-7'>
                            Our vision is to be the go-to destination for artists seeking quality beats and professional services. We aim to continually innovate and expand our offerings, ensuring we meet the evolving needs of the music industry.
                        </p>
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

export default AboutUs;

import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { backend_url } from '../libs/data';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [blurEmail, setBlurEmail] = useState(false);
  const [loading,setLoading] = useState(false)
 const navigate = useNavigate()
  const handleForgotPassword = async(e) => {
    e.preventDefault();
   try{
    setLoading(true)
    await axios.post(`${backend_url}/sendotp`,{
      email
    })
    localStorage.setItem("email",email)
   toast.success("OTP sent successfully")
  navigate('/otpverification')
   }catch(error){
    const errorMessage = error.response && error.response.data.message
    ? error.response.data.message
    : error.message;
    toast.error(errorMessage);

   }finally{
    setLoading(false)
   }
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-bold text-center mb-6">Forgot Password</h2>
        <form onSubmit={handleForgotPassword}>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
               onFocus={() => setBlurEmail(true)}
               onBlur={() => setBlurEmail(false)}
              type="email"
              id="email"
              className={`w-full px-2 focus:outline-none outline-none py-3 border-2 border-opacity-60 ${blurEmail ? "border-[#FF3E41] text-[#FF3E41]" : "border-[#D0D0D0]"} rounded-md transition-border duration-500`}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
          </div>
          <button type="submit" className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition">
            {loading ? "Sending OTP..." : "Send OTP"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ForgetPassword;

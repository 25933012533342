import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import musicIcon from '../assets/musicIcon.svg'

const chartData = {
    labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
    datasets: [
        {
            label: '',
            data: [10000, 15000, 20000, 25000, 30000, 35000, 30000, 40000],
            borderColor: 'red',
            borderWidth: 4,
            backgroundColor: 'transparent',
            fill: false,
            tension: 0.4,
            borderJoinStyle: 'rounded',
            pointRadius: 0,
        },
    ],
};

const chartOptions = {
    scales: {
        y: {
            beginAtZero: true,
            grid: {
                color: '#fff',
            },
            ticks: {
                color: '#fff',
                stepSize: 10000,
                callback: function (value) {
                    return value / 1000 + 'K';
                },
            },
        },
        x: {
            beginAtZero: true,
            ticks: {
                color: '#fff',
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
};

const Card = ({ title }) => (
    <div className="bg-[#141414] text-white p-4 rounded-lg">
        <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg">{title}</h2>
            <span className="text-sm text-gray-400">Last 7 days ▼</span>
        </div>
        <div className="text-3xl mb-4">36502</div>
        <Line data={chartData} options={chartOptions} />
    </div>
);

const ChartsCards = ({ selectedCategory, category, showAll }) => {
    const allCards = () => {
        switch (selectedCategory || category) {
            case 'global':
                return (
                    <>
                        <Card title="Beats Played" />
                        <Card title="Favorites" />
                        <Card title="Total Sales" />
                        <Card title="Wav Downloads" />
                        <Card title="Mp3 Downloads" />
                    </>
                );
            case 'beats':
                return (
                    <>
                        <Card title="Beat Sales 1" />
                        <Card title="Beat Sales 2" />
                        <Card title="Beat Sales 3" />
                    </>
                );
            case 'users':
                return (
                    <>
                        <Card title="User Stats 1" />
                        <Card title="User Stats 2" />
                        <Card title="User Stats 3" />
                        <Card title="User Stats 2" />
                        <Card title="User Stats 3" />
                        <div className=" text-white p-4 flex flex-col gap-5 rounded-lg">
                            <div className="flex bg-[#141414] justify-between items-center px-6 p-4 rounded-lg">
                                <div className="">
                                    <span className="text-[32px] mb-4">10-02-2024</span>
                                    <h2 className="text-xl">Last Session</h2>
                                </div>
                                <img src={musicIcon} alt="" className='' />
                            </div>
                            <div className="flex bg-[#141414] justify-between items-center px-6 p-4 rounded-lg">
                                <div className="">
                                    <span className="text-[32px] mb-4">5h 30min</span>
                                    <h2 className="text-lg">Total Time <br /> Logged In</h2>
                                </div>
                                <img src={musicIcon} alt="" className='' />
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };

    const visibleCards = React.useMemo(() => {
        const cards = React.Children.toArray(allCards());
        return showAll ? cards : cards.slice(0, 3);
    }, [showAll, allCards]);

    const renderTable = () => (
        <div className="lg:px-2 lg:w-full mt-10">
            <div className="overflow-x-auto">
                <table className="min-w-full text-white rounded-lg">
                    <thead>
                        <tr className="text-left bg-[#141414] border-b border-[#9F9F9F]">
                            <th className="p-3">Serial No.</th>
                            <th className="p-3">Name</th>
                            <th className="p-3">Email</th>
                            <th className="p-3">Item</th>
                            <th className="p-3">Amount</th>
                            <th className="p-3">Paid in</th>
                            <th className="p-3">Date</th>
                        </tr>
                    </thead>
                    <tbody className="space-y-2 w-full">
                        <tr className="bg-[#141414] text-[#9F9F9F] rounded-lg">
                            <td className="p-4">1</td>
                            <td className="p-4">Dravid</td>
                            <td className="p-4">david.gray@example.com</td>
                            <td className="p-4">Beat</td>
                            <td className="p-4">$20.00</td>
                            <td className="p-4">Credit</td>
                            <td className="p-4">12/2/24</td>
                        </tr>
                        <tr className="bg-[#141414] text-[#9F9F9F] rounded-lg">
                            <td className="p-4">2</td>
                            <td className="p-4">Smith</td>
                            <td className="p-4">john.smith@example.com</td>
                            <td className="p-4">Beat</td>
                            <td className="p-4">$25.00</td>
                            <td className="p-4">Credit</td>
                            <td className="p-4">12/3/24</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );


    return (
        <div className="lg:p-7">
            <div className="py-4">
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
                    {visibleCards}
                </div>
                {selectedCategory === 'users' && renderTable()}
            </div>
        </div>
    );
};

export default ChartsCards;

import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import oval from '../assets/oval.svg';
import notification from '../assets/notification.svg';
import BeatTypeModal from '../components/BeatTypeModel'; // Adjust the path as necessary
import InviteUserModal from './InviteUserModel';

const UserNavbar = ({ toggleSidebar }) => {
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);
    const navigate = useNavigate()

    const currentPath = location.pathname;

    const handleAddNewBeatsClick = () => {
        setIsModalOpen(true);
    };

    const handleAddNewUserClick = () => {
        setIsInviteUserModalOpen(true);
    };

    return (
        <>
            <div className="bg-[#141414] border-b text-white px-6 py-4 flex justify-between items-center">
                <div className="flex items-center gap-2 cursor-pointer" onClick={()=>navigate('/')}>
                    <img src={oval} alt="Logo" className="h-8 w-8" />
                    <span className="text-xl text-red-500 font-bold">LOGO</span>
                </div>
                <div className="flex items-center gap-4">
                    {currentPath === '/admin/beats-management' && (
                        <button
                            className="bg-red-500 text-white md:text-base text-xs px-1 py-2 md:px-4 rounded-md hover:bg-red-600"
                            onClick={handleAddNewBeatsClick}
                        >
                            Add New Beats
                        </button>
                    )}
                    {(currentPath === '/admin/analytics' || currentPath === '/admin/user-management') && (
                        <button className="bg-red-500 text-white md:text-base text-xs px-1 py-2 md:px-4 rounded-md hover:bg-red-600" onClick={handleAddNewUserClick}>
                            Add New User
                        </button>
                    )}
                    <button className="md:hidden block" onClick={toggleSidebar}>
                        <FaBars className="text-white text-2xl" />
                    </button>
                    {/* <img src={notification} alt="Notification" className="h-6 w-6 sm:h-8 sm:w-8" /> */}
                </div>
            </div>
            <BeatTypeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
            <InviteUserModal isOpen={isInviteUserModalOpen} onClose={() => setIsInviteUserModalOpen(false)} /> {/* Add InviteUserModal here */}
        </>
    );
};

export default UserNavbar;

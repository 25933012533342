import React from 'react'
import Layout from '../layout/Layout'
import MusicList from '../components/MusicList'
import MixingForm from '../components/MixingForm'
import Section from '../components/Section'

const Mixing = () => {
    return (
        <Layout>
            <Section>
                <div className="lg:px-10 px-2">
                    <div className="lg:px-9 py-7">
                        <h1 className='text-center lg:leading-[3.5rem] px-5 py-3 text-3xl lg:text-5xl font-medium'>
                            Professional mixing and mastering to make your tracks shine
                        </h1>
                        <p className='lg:text-center tracking-wider lg:px-8 px-3 font-normal text-lg lg:text-3xl py-5'>
                            Transform your raw recordings into polished, professional tracks with our expert mixing and mastering services. Our experienced engineers will enhance the clarity, balance, and overall quality of your music, ensuring it sounds its best on any platform. Whether you're preparing for release or simply refining your sound, we'll help your tracks reach their full potential and stand out in the industry.
                        </p>
                    </div>

                    <div className="py-12">
                        <h1 className='text-4xl text-center font-bold'>LEASES</h1>
                    </div>

                    <MusicList />
                    <MixingForm />
                </div>
            </Section>
        </Layout>
    )
}

export default Mixing